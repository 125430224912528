<template>
  <div class="pdf-zoom">
    <a @click.prevent.stop="zoomIn" :disabled="isDisabled"><i class="fa fa-search-plus bg-white p-2 mr-1" aria-hidden="true"></i></a>
    <a @click.prevent.stop="zoomOut" :disabled="isDisabled"><i class="fa fa-search-minus bg-white p-2 mr-1" aria-hidden="true"></i></a>
    <a @click.prevent.stop="fitWidth" :disabled="isDisabled"><i class="fa fa-expand bg-white p-2 mr-1" aria-hidden="true"></i></a>
    <a @click.prevent.stop="fitAuto" :disabled="isDisabled"><i class="fa fa-compress bg-white p-2 mr-1" aria-hidden="true"></i></a>
  </div>
</template>

<script>

export default {
  name: 'PDFZoom',

  components: {
  },

  props: {
    scale: {
      type: Number,
    },
    increment: {
      type: Number,
      default: 0.25,
    },
  },

  computed: {
    isDisabled() {
      return !this.scale;
    },
  },

  methods: {
    zoomIn() {
      this.updateScale(this.scale + this.increment);
    },

    zoomOut() {
      if (this.scale <= this.increment) return;
      this.updateScale(this.scale - this.increment);
    },

    updateScale(scale) {
      this.$emit('change', {scale});
    },

    fitWidth() {
      this.$emit('fit', 'width');
    },

    fitAuto() {
      this.$emit('fit', 'auto');
    },
  },
}
</script>

<style>
.pdf-zoom a {
  cursor: pointer;
  font-size: 18px;
}
</style>
